
.center {
    text-align: center;
}

/* Special Offers
   -------------------------------------------------- */
.btn.arrow:after,
a.btn.arrow:after {
  content: "";
  width: 14px;
  height: 14px;
  background-image: url('../images/arrows.png');
  background-size: 70px 14px;
  display: inline-block;
  margin: 0 0 0 10px;
  background-position: -42px;
}

/* ==========================================================================
    Small Screen Navbar Toggle
   ========================================================================== */
.navbar-btn {
  position: relative;
  float: left;
  padding: 5px;
  margin: 5px;
  background-color: $colorWhite;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #153C43;
}
.navbar-btn .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  margin: 5px 3px 6px;
  border-radius: 1px;
  background-color: #153C43;
}
.navbar-btn .fa {
  font-size: 1.5rem;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}


.nav-tabs > li {
    width: 33.33333%;
    text-align: center;
}
.nav-tabs > li:first-child a {
  line-height: 2.8em;
}
@media only screen and (min-width: 445px) {
  .nav-tabs > li:first-child a {
    line-height: 20px;
  }
}

.nav-tabs > li > a,
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
    color: #999;
    font-size: 0.9em;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    color: #153C43;
}

@media only screen and (min-width: 767px) {
    .nav-tabs > li {
        width: auto;
        text-align: left;
    }
}

/* ----- List: No Style ----- */
ul.no-style {
  list-style: none;
  padding-left: 0;
}
/* ----- List: Inline ----- */
ul.inline {
  list-style: none;
  margin-left: 0;
}
ul.inline li {
  float: left;
  margin-right: 15px;
}
/* ----- List: Highlight ----- */
ul.highlight {
  font-size: 1.2em;
}
/* ----- List: Highlight ----- */
ul.no-style {
  padding-left: 0;
  list-style: none;
}
/* ----- List: Arrow ----- */
ul.arrow {
  list-style: none;
  display: table;
  list-style-position: outside;
}
ul.arrow li {
    /*text-indent: -10px;*/
    margin: 0px 0px 0 35px;
    padding-bottom: 15px;
    /*display: table-row;*/
    &.highlight {
        color: rgb(66, 139, 202);

        &:before {
            background-image: none !important;
        }
    }
}
ul.arrow li:before {
  content: "";
  width: 14px;
  height: 13px;
  background-image: url('../Images/arrows.png');
  background-repeat: no-repeat;
  background-size: 70px 14px;
  background-position: -56px;
  display: inline-block;
  margin: 0 10px 0 -30px;
}
ul.arrow.lightgreen li:before,
ul.arrow li.lightgreen:before {
  background-position: 0px;
}
ul.arrow.darkgreen li:before,
ul.arrow li.darkgreen:before {
  background-position: -56px;
}
ul.arrow.grey li:before,
ul.arrow li.grey:before {
  background-position: -14px;
}
ul.arrow.white li:before,
ul.arrow li.white:before {
  background-position: -28px;
}
ul.arrow li a {
  font-weight: 400;
}
/* ----- List: White ----- */
ul.white a {
  color: $colorWhite;
}
/* High DPI Devices
   -------------------------------------------------- */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (-o-device-pixel-ratio: 3/2), only screen and (min-device-pixel-ratio: 1.5) {
  ul.arrow li:before {
    background-image: url('../Images/arrows2x.png');
  }
}

/* Green Contact Icons
   ------------------- */
.contact.green span.icon,
.contact.green a.icon,
.contact.outside.green li:before {
  background-position: 0 -31px;
}
.contact.green a.icon.phone,
.contact.green span.icon.phone,
.contact.outside.green li.icon.phone:before {
  background-position: 0 -31px;
}
.contact.green a.icon.email,
.contact.green span.icon.email,
.contact.outside.green li.icon.email:before {
  background-position: -35px -31px;
}
.contact.green a.icon.chat,
.contact.green span.icon.chat,
.contact.outside.green li.icon.chat:before {
  background-position: -70px -31px;
}

/* White Contact Icons
   ------------------- */
.contact.white span.icon,
.contact.white a.icon,
.contact.outside.white li:before {
  background-position: 0 -62px;
}
.contact.white a.icon.phone,
.contact.white span.icon.phone,
.contact.outside.white li.icon.phone:before {
  background-position: 0 -62px;
}
.contact.white a.icon.email,
.contact.white span.icon.email,
.contact.outside.white li.icon.email:before {
  background-position: -35px -62px;
}
.contact.white a.icon.chat,
.contact.white span.icon.chat,
.contact.outside.white li.icon.chat:before {
  background-position: -70px -62px;
}

/* Ticks and Crosses, Yes and No
   -------------------------------------------------- */
span.no,
span.cross,
span.yes,
span.tick,
span.yesP,
span.tickPublic {
  background-image: url('../Images/cover_indicators.png');
  background-size: 48px 16px;
  background-position: 0 0;
  display: block;
  height: 1px;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 16px;
  width: 16px;
}
span.no,
span.cross {
  background-position: -16px;
}
span.yes,
span.tick {
  background-position: 0;
}
span.yesP,
span.tickPublic {
  background-position: -32px;
}

/* Special Offers
   -------------------------------------------------- */
.btn.arrow:after,
a.btn.arrow:after {
  content: "";
  width: 14px;
  height: 14px;
  background-image: url('../Images/arrows.png');
  background-size: 70px 14px;
  display: inline-block;
  margin: 0 0 0 10px;
  background-position: -42px;
}


.progress {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}


/* Fixed contact us slider */
    #glb-contact-us-tab {
        position:fixed;
        right:0px;
        top:300px;
        width: 59px;
        height: 239px;
        z-index: 4000;
    }

        #glb-contact-us-tab a {
            display: block;
            width: 59px;
            height: 239px;
            background: transparent url('../Images/contact-frank.png') top left no-repeat;
        }

            #glb-contact-us-tab a span {
                display:none;
            }

    #glb-contact-us {
        display: none;
        z-index: 3999;
        position: absolute;
        top: 182px;
        height: 1000px;
        left: 0px;
        right: 0px;
        background: none repeat scroll 0% 0% rgba(255, 255, 255, 0.7);
        overflow: hidden;
    }

        #glb-contact-us .overlay-inner {
            position: absolute;
            width: 740px;
            padding: 20px 60px;
            background: none repeat scroll 0% 0% #FFF;
            top: 0px;
            bottom: 0px;
            right: 0px;
            box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.3);
        }

        #glb-contact-us .close-btn {
            float: right;
            margin-right: -6%;
        }

            #glb-contact-us .close-btn a {
                font-size: 20px;
                color: #000;
                padding-right: 30px;
                background: url("../Images/close-btn.png") no-repeat scroll right center transparent;
            }


table tr td.gsc-search-button, table tr td.gsc-input, .gsc-control-cse table tr td, .gsc-control-searchbox-only table tr td {
    border: none;
}

/*.gsc-above-wrapper-area, */
.gcsc-branding, .breadcrumbs {
    display: none;
}
.gsc-above-wrapper-area {
    border-bottom: none!important;
}
.gs-spelling a {
    color: #FC554F!important;
}
.cse input.gsc-search-button,
input.gsc-search-button,
td.gsc-search-button {
    /*color: #FFF;
    padding: 6px 27px!important;*/
    margin-top: 5px!important;
}
input#gsc-i-id1 {
    /*border: 1px solid #FF0000!important;*/
    margin-top: 2px!important;
    line-height: 0px;
}
td#gs_tti50 {
    margin-top: 0px!important;
    padding-top: 0px!important;
    line-height: 0px;
}
#gsc-iw-id1 {
    height: 30px!important;
}
table#gs_id50, #gs_id50 tbody {
    margin-top: 0px;
    line-height: 0px;
}
.gsib_b {
    padding-top: 5px;
}

.gsc-search-button .gsc-search-button-v2,
input.gsc-search-button {
    color: $colorWhite;
    font-size: 14px;
    margin-left: 0px;
}
.gsc-table-result,
.gsc-thumbnail-inside,
.gsc-url-top {
    padding-left: 0px!important;
}
.searchWrapper table tr td {
    padding: 0px;
    border: none;
}
.gsc-result-info {
    padding: 8px 8px 10px 0px!important;
}
.gstl_50 gssb_c {
    margin-top: 0px;
}

table.gsc-completion-container tr td,
table.gsc-completion-container tbody tr td div table tbody tr td,
td.gssb_e {
    border: none;
    line-height: 1.4;
}
div.header table tr td.gsc-input,
div.header table tr td.gsc-search-button {
    padding: 0px!important;
}
div.header table tr td {
  border: none;
  padding: 0px 8px 0px 8px;
}
.gsc-control-wrapper-cse {
    color: #153c43;
}

.homepage .col-xs-6 img {
    margin: 0px 50px 100px 0px;
}
p.homepage-info-text {
    min-height: 115px;
}
.gsc-input {
    line-height: 18px!important;
}
