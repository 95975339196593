/* =============================================================================
   Frank Australia
   ========================================================================== */

/* --------------------------------------------------
   Table of Contents
-----------------------------------------------------
    :: Links
    :: Base Layout
    :: Bootstrap Over-write
    :: Headers
    :: Frank Logo
    :: Container Sizes
    :: Shared Elements
    :: Header
        :: Search Box
        :: Navigation
    :: Homepage
        :: Cover Options
    :: Jumbotron: Focus Panel
    :: Application Process
    :: Table
        :: Cover Table
        :: Comparison Table
    :: Health Cover
    :: Footer
*/

/* =============================================================================
   Links
   ========================================================================== */

a {
    color: $colorMediumPurple;

    &:hover {
        color: $colorBlack;
    }
}

a.text-link {
    color: $colorMediumPurple;

    &:hover,
    &:active,
    &:focus {
        color: $colorBlack;
    }
}

a.ovhc-popover {
    color: #153C43;
    text-decoration: none;
}

a.ovhc-popover:hover {
    cursor: pointer;
}

a.underline {
    text-decoration: underline;
}

a.underline:hover {
    text-decoration: none;
}

a.dashed {
    border-bottom: 1px dashed #153c43;
}

/* =============================================================================
   Base Layout
   ========================================================================== */

html, body {
    height: 100%;
}

html{
    overflow: scroll;
}::-webkit-scrollbar {
     width:0px;
     background: transparent;
}

body {
    // font-family: 'Nunito', sans-serif;
    font-family: 'Montserrat', 'sans-serif';
    font-weight: 500;
    color: $colorBlack;
}

p {
    font-size: 16px;
    margin: 21px 0 21px 0;

    &.notes {
        font-size: 12px;
    }
}

a:focus {
    outline: none;
}

ul li,
ol li {
    font-size: 16px;
}

.pt-10 {
    padding-top: 10px
}

.pt-20 {
    padding-top: 20px
}

.pt-40 {
    padding-top: 40px
}

.pb-10 {
    padding-bottom: 10px
}

.pb-20 {
    padding-bottom: 20px
}

.pb-40 {
    padding-bottom: 40px
}
.p-10{
    padding:10px;
}

.mt-0 {
    margin-top: 0
}

.mt-10 {
    margin-top: 10px
}

.mt-20 {
    margin-top: 20px
}

.mt-20-important {
    margin-top: 20px!important;
}

.mt-40 {
    margin-top: 40px
}

.mb-0 {
    margin-bottom: 0
}

.mb-10 {
    margin-bottom: 10px
}

.mb-20 {
    margin-bottom: 20px
}

.mb-40 {
    margin-bottom: 40px
}

/* =============================================================================
   Bootstrap Over-write
   ========================================================================== */

/*
 * Remove Bootstrap padding
 */
.container-fluid {
    padding: 0;
}

.mid-container {
    margin: 0 auto;
    text-align: center;
}

.body-content > .container {
    margin-top: 20px;
    margin-bottom: 40px;
    padding-right: 20px;
    padding-left: 20px;
}

.modal-body.content h2 {
    width: 80%;
    margin-top: 0;
}


/* =============================================================================
   Headers
   ========================================================================== */

h1,
h1 a {
    // font-family: "Arista", Verdana, Arial, sans-serif;
    font-size: 40px;
    line-height: 1em;
    color: $colorBlack;
    font-weight: 700;
}

h2 {
    // font-family: "Nunito Regular", Verdana, Arial, sans-serif;
    font-size: 32px;
    line-height: 1.4em;
    color: $colorBlack;
    margin: 45px 0 21px 0;
}

.homepage h2 {
    margin: 20px 0;
    font-size: 28px;
}

@media only screen and (min-width: 767px) {
    h1, h1 a {
        font-size: 60px;
    }

    .homepage h2 {
        margin: 0 0 20px;
    }
}


/* =============================================================================
   Frank Logo
   ========================================================================== */

a.logo {
    float: left;
    display: none;
    height: auto;
    overflow: hidden;
    margin: 20px 0 3rem;
    width: 170px;

    span {
        @extend %visually-hidden;
    }
}


/* =============================================================================
   Container Sizes
   ========================================================================== */

.header-container > div > div {
    max-width: 1170px;
    margin: 0 auto;
}

/* =============================================================================
   Shared Elements
   ========================================================================== */

.question-pop {
    background: transparent url("../Images/popover-about2.png") no-repeat;
    width: 28px;
    height: 28px;
    display: inline-block;
}

.information-pop {
    background: transparent url("../Images/popover-about.png") no-repeat;
    width: 26px;
    height: 26px;
    display: inline-block;
}

select, option {
    font-weight: 500;

    &::-ms-expand {
        display: none;
    }
}


// Error message color
.error-message {
    color: $color-error;
}


/* =============================================================================
   Header
   ========================================================================== */

.header {
    background-color: $brand-primary;
    /*position: fixed;*/
    width: 100%;
    height: 50px;
    z-index: 3;
    top: 0;

    &__banner_ie {
        background-color: #fce7e7;
        color: #f00;
        font-size: medium;
        padding: 10px;
        text-align: center;
    }
}

.body-content,
.header-container > .navbar-inverse {
    width: 100%;
}

.header .title {
    margin: 0;
    text-align: center;
    float: left;
    width: 75%;
    color: #002930;
    font-size: 3.5rem;
    // font-family: "Arista", Verdana, Arial, sans-serif;
    font-weight: 700;
}

.navbar-plain {
    background-color: #01282F;
    height: 60px;
}

.header .navbar-btn {
    background-color: transparent;
    margin: 5px 0 0;
    border: 0;
}

.header .navbar-btn.with-icon {
    background: transparent url('../Images/frank-icon.png') no-repeat top left;
    background-size: 40px;
    border-color: #92C286;
}

.header .with-icon .icon-bar {
    visibility: hidden;
}

.header .mid-container {
    padding-top: 15px;
}

.header .contact {
    float: right;
    margin-top: 30px;

    &__item-one {
        margin-right: 25px;
    }

    a {
        color: #153c43;
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 4rem;

        &:hover,
        &:active {
            border-bottom: 3px solid #153C43;
            text-decoration: none;
        }
    }

    &__item-two {
        padding-left: 30px;

        a {
            position: relative;
        }
    }

    .icon-member {
        left: -30px;
        position: absolute;
        top: 7px;
    }
}

.dropdown-toggle {
    cursor: pointer;
}

@media only screen and (min-width: 767px) {
    .header {
        /*position: relative;*/
        height: auto;
    }

    .header .title {
        display: none;
    }

    .body-content,
    .header-container > .navbar-inverse {
        margin-top: 0;
    }

    .header-container > .navbar-inverse.navbar-collapse {
        display: block;
        visibility: visible;
    }

    /* Logo and Hamburger */
    .header .navbar-btn {
        display: none;
    }

    a.logo {
        display: block;
    }

    .header .contact.buttons {
        padding-top: 30px;
    }
}


/* ==========
   Search Box
   ========== */
.cse .gsc-search-button input.gsc-search-button-v2, input.gsc-search-button-v2 {
    width: 40px !important;
    height: 30px !important;
    padding: 7px 11px !important;
    background: #002830 !important;
    background-color: #002830 !important;
    border-color: #002830 !important;
    filter: none !important;
}

input.gsc-search-button-v2 {
    width: 13px;
    height: 13px;
    padding: 6px 27px;
    min-width: 13px;
    margin-top: 2px;
}

.cse input.gsc-search-button,
input.gsc-search-button {
    // font-family: inherit;
    font-size: 11px;
    font-weight: bold;
    color: #fff;
    padding: 0 8px;
    height: 29px;
    min-width: 54px;
    border: 1px solid #666666;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-color: #3079ed;
    background-color: #4d90fe;
}

.gsc-search-button .gsc-search-button-v2,
input.gsc-search-button {
    color: $colorWhite;
    font-size: 14px;
    margin-left: 0px;
}


/* ==========
   Navigation
   ========== */

.header-container > .navbar-inverse {
    background-color: $colorBlack;
    margin-bottom: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

ul.main.nav > li a {
    align-items: center;
    color: $colorWhite;
    font-size: 0.9em;
    line-height: 30px;
    padding: 5px 10px;
    text-align: center;

    @media screen and (min-width: 767px) {
        display: flex;
        min-height: 50px;
        line-height: 20px;
        justify-content: space-around;
    }
}

.main.navbar-nav {
    margin: 0;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus,
ul.main.nav > li a:hover,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    background-color: $color-neutral-dark;
}

.navbar-nav > li > .dropdown-menu {
    background-color: $colorBlack;
    font-size: 1.2em;
    text-align: left;
    margin-top: 10px;
    padding: 0;
}

.navbar-nav > li > .dropdown-menu:before {
    position: absolute;
    top: -7px;
    left: 9px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #01282F;
    border-left: 7px solid transparent;
    border-bottom-color: #01282F;
    content: '';
}

.navbar-nav ul.dropdown-menu > li a {
    // text-align: left;
    padding: 10px 30px;
}

@media only screen and (max-width: 767px) {
    .navbar-nav > li > .dropdown-menu {
        padding: 15px 0;
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
        color: $colorWhite;
        padding: 15px;
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
        background-color: $color-neutral-dark;
    }

    .navbar-nav > li > .dropdown-menu:before {
        display: none;
    }
}

@media only screen and (min-width: 767px) {
    .navbar-nav > .nav-health-cover > a {
        width: 110px;
    }

    .navbar-nav > .nav-switch-to-frank > a {
        width: 130px;
    }

    .navbar-nav > .nav-members > a {
        width: 110px;
    }

    .navbar-nav > .nav-why-choose-frank > a,
    .navbar-nav > .nav-australian-healthcare > a,
    .navbar-nav > .nav-visa-and-immigration > a {
        width: 140px;
    }

    .navbar-nav > .nav-manage-your-cover,
    .navbar-nav > .nav-manage-your-cover > a {
        width: 170px;
    }
}


/* =============================================================================
   Homepage
   ========================================================================== */

body.homepage {
    background: transparent url("../Images/homepage-background-slice2.png") repeat-x !important;
}

.homepage-intro .arrow h4 {
    margin: 0;
    display: inline;
    font-size: 2rem;
}

.homepage-intro ul.three-quart {
    max-width: 560px;
    padding-left: 10px;
}

.popover[role="tooltip"] {
    z-index: 100000;
}

.popover > .popover-title,
.popover > .popover-content {
    // font-family: 'Nunito Regular', Verdana, Arial, sans-serif;
    color: #153C43;
}

.popover[role="tooltip"] > .popover-content {
    font-size: 1.2rem;
}

@media screen and (min-width: 1200px) {
    .immigration-stamp {
        background: transparent url("../Images/immigration-stamp.png") no-repeat center;
        background-size: 185px 133px;
    }

    .overseas-cover-benefits.immigration-stamp {
        background-position: top right;
    }
}

.overseas-cover-benefits h4 {
    display: inline;
}

.overseas-cover-benefits a {
    color: inherit;
    text-decoration: underline;
}

/* =============
   Cover Options
   ============= */
div.cover-options {
    padding-top: 0;
}

.cover-options > div {
    padding-right: 10px;
}

.cover-options > .popover {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    z-index: 0;
    margin-left: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    box-shadow: none;
}

.cover-options p {
    margin: 0px 0 10px;
}


ul.cover-options {
    display: inline-block;
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0;
}

ul.cover-options li {
    margin-right: 15px;
    margin-bottom: 10px;
    line-height: 2.5em;
    text-align: center;
    cursor: pointer;
    float: left;
    display: inline-block;
    width: 100%;
}

ul.cover-options li a {
    color: #153C43;
    display: block;
    height: auto;
    float: left;
    font-size: 13px;
}

ul.cover-options li.not-covered a {
    color: #becbce;
}

ul.cover-options li > span {
    background: transparent url("../Images/cover-images.png") no-repeat center top;
    background-size: 245px 35px;
    display: inline-block;
    width: 35px;
    height: 35px;
    float: left;
    margin-right: 20px;
}

ul.cover-options li.public-hospital > span {
    background-position: 0 top
}

ul.cover-options li.repatriation > span {
    background-position: -35px
}

ul.cover-options li.emergency-ambulance > span {
    background-position: -70px
}

ul.cover-options li.specialist > span {
    background-position: -105px
}

ul.cover-options li.gp-services > span {
    background-position: -140px
}

ul.cover-options li.dental > span {
    background-position: -175px
}

@media only screen and (max-width: 480px) {
    ul.cover-options li {
        line-height: 1.1em;
        text-align: left;
    }

    ul.cover-options li a {
        float: none;
    }
}

@media only screen and (max-width: 768px) {
    div.cover-options .arrow {
        display: none
    }

    ul.cover-options li a {
        line-height: 2.5em;
    }
}

@media only screen and (min-width: 768px) {
    ul.cover-options li {
        width: 100px;
        height: 100px;
        line-height: 1.1em;
    }

    ul.cover-options li > span {
        float: none;
        margin-right: 0;
    }

    ul.cover-options li a {
        float: none;
    }
}


/* =============================================================================
   Jumbotron: Focus Panel
   ========================================================================== */

div.container .jumbotron {
    padding: 25px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

div.container .jumbotron h3 {
    margin-bottom: 20px;
}

div.container .jumbotron p {
    font-size: 1.2em;
    line-height: 1.4em;
}

div.container .jumbotron img.homepage-info-img {
    margin-right: 50px;
}

@media only screen and (min-width: 768px) {
    div.container .jumbotron p {
        width: 80%;
    }
}

@media only screen and (min-width: 1200px) {
    div.information {
        min-height: 180px;
    }

    div.information div.link-offset {
        position: absolute;
        bottom: 0;
    }
}


/* =============================================================================
   Application Process
   ========================================================================== */

.apply-now header {
    width: 100%;
    display: inline-block;
    position: relative;
}

.apply-now h4 {
    float: left;
    max-width: 160px;
}

.apply-now .flat-intro {
    display: none;
}

.apply-now .flat-intro p {
    margin: 20px 0 21px;
    padding: 0;
    line-height: 1.2em;
    vertical-align: middle;
    color: $colorWhite;
}

.apply-now header span.info {
    position: absolute;
    top: 10px;
    right: 0;
}

.apply-now header .popover[role="tooltip"] {
    width: 300px;
}

.apply-now select {
    color: #153C43;
}

.apply-now .btn-primary {
    width: 100%;
}

.apply-now > .container {
    max-width: 100%;
    height: 470px;
    background-color: $color-neutral-dark;
    color: $colorWhite;
    margin-right: 0;
}

.control-apply-flat .btn-apply-callout {
    margin-left: 20px;
}

.payment-options {
    width: 100%;
}

.payment-options a {
    display: block;
    margin: 0 auto;
    width: 88px;
    height: 29px;
    background: transparent url("../Images/payment-options2.png") no-repeat center;
}

.cover-price {
    color: $colorWhite;
    margin-top: 13px;
}

.cover-price span {
    font-size: 40px;
}

.cover-number-people,
.frequency span {
    color: $colorWhite;
}


.cover-number {
    margin: 12px 0 21px 0;
}

.cover-number > div {
    display: table-cell;
}

.cover-number .cover-number-people {
    display: none;
}

.cover-number label {
    border: solid 1px darken($colorWhite, 50%);
    background-color: transparent;
    min-width: 44px;
    min-height: 44px;
    text-align: center;
    color: darken($colorWhite, 30%);
    font-weight: 500;
    font-size: 21px;
    padding: 6px 4px 4px 4px;
    margin: 0;
    cursor: pointer;
}

.cover-number label.selected {
    border: solid 1px $brand-primary;
    background-color: $brand-primary;
    color: #153c43;
}

.cover-number label input[name='Number_covered'] {
    display: none;
}

.cover-number .cover-number-people {
    display: none;
}


@media only screen and (min-width: 767px) {
    .apply-now {
        padding: 0;
    }

    .apply-now > .container {
        max-width: 263px;
    }
}


.control-apply-flat {
    background-color: darken($color-neutral-dark, 5%);
    padding: 5px 0;
}

.control-apply-flat .apply-now {
    width: 100%;
    height: initial;
    height: auto;
    margin-top: 0;
    overflow: hidden;
}

/**
 * Show and hide the top level application form
 * Hides on small screens. Shows about 992px
 */
.body-content > .control-apply-flat:first-child {
    display: none;
}

@media only screen and (min-width: 992px) {
    .body-content > .control-apply-flat:first-child {
        display: block;
    }
}

/**
 * Flat Intro and Apply Column inherit Bootstrap spacing styles
 */
.control-apply-flat .flat-intro,
.control-apply-flat .control-apply-column {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 992px) {
    .control-apply-flat .flat-intro,
    .control-apply-flat .control-apply-column {
        float: left;
    }

    .control-apply-flat .flat-intro {
        width: 33.33333333%;
    }

    .control-apply-flat .control-apply-column {
        width: 66.66666667%;
    }
}

@media only screen and (min-width: 992px) {
    .control-apply-flat .control-apply-column-right {
        float: right;
    }

    .control-apply-flat .control-apply-column-right > div {
        float: left;
    }
}


.control-apply-flat .cover-number {
    float: left;
    margin: 20px 20px 21px;
}

@media only screen and (min-width: 767px) {
    .control-apply-flat .cover-number .cover-number-people {
        margin-left: 20px;
        display: block;
    }

    .control-apply-flat .cover-number {
        margin: 12px 20px 21px;
    }
}


.control-apply-flat .cover-price {
    margin: 3px;
    padding-top: 5px;
}

.control-apply-flat .frequency span {
    color: $colorWhite;
}

.control-apply-flat .apply-now .flat-intro {
    display: inline-block;
}

.control-apply-flat .intro,
.control-apply-flat .outro,
.control-apply-flat .payment-options,
.control-apply-flat .get-letter {
    display: none;
}


/* =============================================================================
   Table
   ========================================================================== */

table tr th {
    background-color: $brand-primary;
    vertical-align: middle;
    padding: 20px 10px;
    border: 1px solid #ddd;
    font-weight: 500;
}

table tr th p {
    font-size: 18px;
}

table tr th.blank {
    background-color: #133b44;
}

table tr td {
    border: 1px solid #ddd;
    padding: 10px;
}

table .center {
    text-align: center;
}

table p {
    padding: 0;
    margin: 0;
    font-size: 14px;
}

table th.grey {
    width: 20%;
}

table th.grey,
table th.grey p {
    background-color: #EEEEEE;
    text-align: left;
}

table th.grey p {
    font-size: 1em;
    line-height: 1.2em;
}

/* ===========
   Cover Table
   =========== */

table.cover-table th.colService,
table.cover-table td.colService {
    width: 40%;
}

table.cover-table td.colService {
    border-right: none;
}

table.cover-table th.colInfo,
table.cover-table td.colInfo {
    width: 10%;
}

table.cover-table td.colInfo {
    border-left: none;
}

table.cover-table td.colCoverage {
    width: 50%;
    text-align: center;
}

table.cover-table td.colCoverage p {
    padding: 0 40px;
}

/* ================
   Comparison Table
   ================ */

@media only screen and (min-width: 767px) {
    table.comparison {
        width: 90%;
    }
}

/* Reset the no-wrap on smaller screens */
@media screen and (max-width: 767px) {
    .table-responsive > .table > thead > tr.text > th,
    .table-responsive > .table > tbody > tr.text > th,
    .table-responsive > .table > tfoot > tr.text > th,
    .table-responsive > .table > thead > tr.text > td,
    .table-responsive > .table > tbody > tr.text > td,
    .table-responsive > .table > tfoot > tr.text > td {
        white-space: normal;
    }
}

/* =============================================================================
   Health Cover
   ========================================================================== */

.tab-content > .tab-pane > h3:first-child {
    margin: 40px 0 20px;
}

/* =============================================================================
   Footer
   ========================================================================== */

footer {
    background-color: $colorBlack;
    font-size: 1.1em;
    padding: 30px 0;
    text-align: center;
}

@media only screen and (min-width: 768px) {
    footer {
        text-align: left;
    }
}

footer h4 {
    color: $brand-primary;
    font-size: 1.2em;
    margin-top: 40px;
}

footer a,
footer p {
    color: $colorWhite;
}

footer a:hover {
    color: $colorWhite;
}

footer.full .container {
    height: 294px;
    margin-top: 20px;
}


.modal-header {
    padding: 15px;
}

.modal-body {
    padding: 10px 25px;
}

.modal button.close {
    font-size: 2em;
}

/* =============================================================================
   profile-builder
   ========================================================================== */

.profile-builder__container {
    background-color: $brand-primary;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100vw;

    .profile-builder {

        select {
            border: none;

            &::-ms-expand {
                display: none;
            }
        }

        .profile-builder__show-me .btn {
            font-size: 12px;
        }

        h1, .h1-style {
            margin-top: 1em;
            margin-bottom: 0;
            font-weight: bold;
        }
    }

    .profile-builder__title {
        color: $colorBlack;
        font-size: 3rem;

        @media screen and (min-width: 768px) {
            font-size: 4.5rem;
        }
    }

    .profile-builder__tagline {
        letter-spacing: -.02em;
        color: $colorBlack;
        font-weight: 700;
        line-height: 1.75;
        position: relative;
        -webkit-transition: -webkit-transform .5s cubic-bezier(.5,0,0,1);
        transition: -webkit-transform .5s cubic-bezier(.5,0,0,1);
        transition: transform .5s cubic-bezier(.5,0,0,1);
        transition: transform .5s cubic-bezier(.5,0,0,1),-webkit-transform .5s cubic-bezier(.5,0,0,1);
        text-align: left;
        top: 0;
        white-space: normal;
        width: 100% !important;

        @media screen and (min-width: 768px) {
            max-width: 80vw;
        }
    }

    .profile-builder__comma {
        -webkit-transition: opacity,.5s linear;
        transition: opacity,.5s linear;
        opacity: 1
    }

    .profile-builder__line {
        display: block;
        font-size: 1.3em;
        opacity: 1;
        line-height: 1.9;
        position: relative;

        @media screen and (min-width: 400px) {
            font-size: 1.6em
        }

        @media screen and (min-width: 470px) {
            font-size: 2em
        }

        @media screen and (min-width: 600px) {
            font-size: 2.5em
        }

        @media screen and (min-width: 829px) {
            font-size: 3em
        }
    }

    .profile-builder__show-me {
        margin-top: 1.5em;

        .btn {
            padding: .5em 6em;
            font-size: .33em;
        }
    }

    .select-styled {
        color: $color-neutral-dark;
        cursor: pointer;
        position: relative;
        display: inline-block;
        background-color: #fff;
        line-height: 1;
        white-space: nowrap;
        box-shadow: .18em .18em 0 0 rgba(0,0,0,.1);
        margin: 0 .1em;
        margin-top: -.1em;
        vertical-align: middle;
        /*padding: .1875em .4375em .1875em;*/
        padding: .1em .4em .2em .4em;
        border-radius: 0;
        font-weight: 700
    }
    /*@media screen and (min-width: 768px) {
    .select-styled {
        padding: .1875em .5625em .25em
    }
}

@media screen and (min-width: 1024px) {
    .select-styled {
        padding: .171875em .4375em .265625em
    }
}*/
    .select-styled:hover {
        -webkit-animation: button-hover 800ms linear;
        animation: button-hover 800ms linear
    }

    .sticky-profile .select-styled {
        font-size: 1.125rem;
        padding: .57em 1em
    }

    .select-styled:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff
    }

    .select-styled__selected {
        /*z-index: 10;*/
        position: relative
    }

    .inactive .select-styled__selected {
        color: #d5d5d5
    }

    .select-styled select {
        position: absolute;
        top: 50%;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        font-size: 1rem;
        border: 1px solid transparent;
        cursor: pointer;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 12;
        display: none
    }

    .touch .select-styled select {
        display: block
    }

    .select-styled__dropdown {
        background-color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        visibility: hidden;
        z-index: 11;
        text-align: left;
        box-shadow: .18em .18em 0 0 rgba(0,0,0,.1);
        display: inline-block
    }

    .select-styled__option {
        display: block;
        cursor: pointer;
        white-space: nowrap;
        color: $color-neutral-dark;
        font-size: .75em;
        padding: .5em .55em
    }

    @media screen and (min-width: 768px) {
        .select-styled__option {
            font-size: .65em;
            padding: .5em .9em
        }
    }

    @media screen and (min-width: 1024px) {
        .select-styled__option {
            font-size: .5em;
            padding: .71em 1em .79em
        }
    }

    .select-styled__option:hover {
        background-color: #e6e5e5;
        color: $colorBlack
    }

    .select-styled.open {
        z-index: 20
    }

    .select-styled.open:hover {
        -webkit-animation: none;
        animation: none
    }

    .select-styled.open .select-styled__selected {
        z-index: 21
    }

    .select-styled.open .select-styled__dropdown {
        -webkit-animation: dropdown-open 800ms linear both;
        animation: dropdown-open 800ms linear both;
        visibility: visible
    }

    .select-styled.open .select-styled__dropdown:before {
        content: '';
        background: #fff;
        position: absolute;
        left: 0;
        top: 0%;
        width: 100%;
        height: 5px;
        z-index: -1;
        -webkit-animation: dropdown-white-line 800ms linear;
        animation: dropdown-white-line 800ms linear
    }

    .select-styled.closed:after {
        -webkit-animation: dropdown-closed 800ms linear both;
        animation: dropdown-closed 800ms linear both
    }
}


/* =============================================================================
   homepage-intro-blurb
   ========================================================================== */
.homepage-intro-blurb__container {
    background-color: #ac4fc6;
    color: $colorBlack;

    .homepage-intro-blurb {
        margin-bottom: 3rem;
    }

    .immigration-stamp {
        background-position: right;
    }

    h2 {
        color: black;
        font-weight: bold;
    }

    h4 {
        font-weight: bold;
    }

    .arrow li a {
        font-weight: bold;
    }
    /* ----- List: Arrow ----- */
    .arrow {
        display: table;
        list-style-position: outside;
    }

    .item {
        /*text-indent: -10px;*/
        margin: 0px 0px 0 35px;
        padding-bottom: 15px;
        /*display: table-row;*/
    }

    .arrow h4:before {
        content: '\f061';
        font-family: $fontawesome-font-family;
        font-size: 1.5em;
        position: absolute;
        left: 0.5em;
        top: -.25em;
    }
}

/* =============================================================================
   homepage-links
   ========================================================================== */

.homepage-links__container {
    background-color: $brand-primary;
    font-weight: 400;
    padding: 15px 0;
}

.accordion {


    &__inner {
        margin-bottom: 40px;
    }

    .homepage-links {
        margin-bottom: 30px;

        h2 {
            margin-top: 1em;
            font-weight: 700;
        }

        .section {
            margin: -2px 0 0 0;
            border-top: 2px solid $colorBlack;
            border-bottom: 2px solid $colorBlack;

            h3 {
                width: 100%;
                font-size: 1.1em;
                font-weight: bold;
                margin: 0;
                padding: 1.3em 0;
                line-height: 1.5em;
            }
        }

        button {
            width: 100%;
            text-align: left;

            .expander-icon {
                float: right;
                font-size: 2.4em;
                margin-top: -.24em;
            }
            
            .expander-icon {
                transition: transform 200ms ease;
                transform: rotate(180deg) scale(.7);
            }
            &.collapsed .expander-icon {
                transition: transform 200ms ease;
                transform: rotate(0) scale(.7);
            }
        }
    }
}

// Remove button styling on accordion buttons
button[data-toggle="collapse"] {
    background: transparent;
    border: 0;
    appearance: none;
}

.homepage-links__container:not(.accordion) {

    .info-box {
        background-color: white;
    }
}

.homepage-links__container {
    .homepage-links {

        a {
            color: inherit;
            text-decoration: underline;
        }

        .info-box {
            padding: 2em;
            margin: 1.5em 0;

            h2 {
                margin-top: 0;
                font-weight: bold;
            }

        }
    }
}

/* =============================================================================
   homepage-links
   ========================================================================== */

.compare-products {

    h3 {
        font-weight: bold;
    }

    .select-styled {
        box-shadow: none;
        border-width: 0 0 2px 0;
        border-color: $colorBlack;
    }

    .profile-builder__line {
        font-size: medium;

        select {
            font-size: xx-large;
            font-weight: 700;
            
            &::-ms-expand {
                display: none;
            }

            @media screen and (min-width: 768px) {
                display: block;
            }

            @media screen and (min-width: 1024px) {
                display: inline-block;
            }
        }
    }
}

/* =============================================================================
   New work isolated to not affect existing site: 
   default, compare products, product details
   ========================================================================== */
.profile-builder, .compare-products, .product-details {
    .select-styled, .selection select { // adapted from https://www.filamentgroup.com/lab/select-css.html
        /*display: block;
        font-size: 16px;
        font-family: sans-serif;
        font-weight: 700;
        color: #444;*/
        line-height: normal;
        padding: .14em 1.4em .2em .2em;
        /*width: 100%;*/
        max-width: 100%;
        box-sizing: border-box;
        /*margin: 0;
        border: 1px solid #aaa;
        box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
        border-radius: .5em;*/
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        /*background-color: #fff;*/
        /*background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);*/
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22black%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat, repeat;
        background-position: right .35em top 50%, 0 0;
        background-size: .45em auto, 100%;
    }

    .select-css::-ms-expand {
        display: none;
    }

    .select-css:hover {
        border-color: #888;
    }

    .select-css:focus {
        border-color: #aaa;
        box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        color: #222;
        outline: none;
    }

    .select-css option {
        font-weight: normal;
    }

    .selection select { // adapted from https://www.filamentgroup.com/lab/select-css.html
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22white%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    }

    .fa-check {
        color: $color-success;
    }

    .fa-times {
        color: $color-error;
    }

    .fa-exclamation {
        color: $color-warning;
    }

    thead td {
        border-width: 0px 1px;
    }

    tbody td {
        border-width: 0px 1px 1px 1px;
    }

    .product-header {
        .product-name {
            font-size: 1.65em;
            line-height: 1em;
            margin-top: 0;
            font-weight: inherit;
            white-space: nowrap;
        }

        .product-price {
            font-size: 2.75em;
            line-height: 1em;
            margin-top: 0;
            margin-bottom: .125em;
            font-weight: bold;
            white-space: nowrap;

        }
        }

        .product-next-btn {
            display: block;
            margin-top: 1.818181818em;
            margin-bottom: 1em;
            min-width: 0;
        }

        .fas {
            text-decoration: inherit;
        }

        .product-header {
            color: #fff;
            background-color: $colorBlack;
            text-align: center;
            border-top: 60px solid $colorGrayLight;

            .product-title {
                text-align: center;
                color: inherit;
                font-size: 1.2em;
                line-height: 1.2;
                margin-top: .2em;
                margin-bottom: 1.1em;
                font-weight: 500;
                height: 45px;
            }

            .product-price {
                sup {
                    vertical-align: super;
                    font-size: .725em;
                    top: .25em;
                    position: relative
                }

                @media all and (-ms-high-contrast:none),(-ms-high-contrast:active) {
                    sup {
                        top: .45em
                    }
                }

                span sup {
                    margin-right: 0;
                    font-size: .4em;
                    position: relative;
                    top: -.25em
                }

                @media all and (-ms-high-contrast:none),(-ms-high-contrast:active) {
                    span sup {
                        top: 0
                    }
                }
            }

            select {
                background-color: $colorBlack;
                border-color: $colorWhite;
                border-width: 0 0 1px 0;
                border-style: solid;
                padding-bottom: .25em;
                width: 100%;

                &::-ms-expand {
                    display: none;
                }
            }

            .btn--primary-on-dark {
                border-color: $brand-primary;
                background-color: $colorBlack;
                color: $brand-primary;
            }

            .btn--primary-on-dark:hover {
                background-color: $brand-primary;
                color: $colorBlack;
            }

            .link {
                color: $colorWhite;
                text-decoration: underline;
                text-transform: none;
            }
        }

        #product-header-sticky.slide-out {
            top: -300px;
            transition-property: top;
            transition-duration: .5s;
        }

        #product-header-sticky.slide-in {
            transition-property: top;
            transition-duration: .5s;
        }
    }
    /* =============================================================================
   compare products
   ========================================================================== */
    .compare-products {

        @media screen and (max-width: 991px) {
            .product-table-container {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                margin-left: 0;
                margin-right: 0;
            }
        }

        @media screen and (max-width: 450px) {
            #product-table-wrapper {
                scroll-snap-type: x mandatory;
                overflow-x: scroll;
                td:not(.separator) {
                    scroll-snap-align: center;
                }
            }

            #product-table-wrapper::-webkit-scrollbar{
                display: none;
            }
            .product::-webkit-scrollbar{
                background: transparent;
                width: 0;
                height:0;
            }
        }

        .select-styled {
            background-color: transparent;
        }

        .product {
            height: 100%;
            font-size: inherit;
            color: $color-neutral-mid;
            border-bottom: 3.75em solid #fff;
            width: 100%;
            overflow-x: scroll;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            // prevent unwanted scrollbars in IE11 / Edge
            // see https://css-tricks.com/snippets/css/hide-scrollbar-in-edge-ie-1011/
            border-radius: 0 0 0 0;
            /*box-shadow: 0 .1em 0 0 rgba(0,0,0,.1);*/
            margin-top: 3em;
            margin-bottom: .2em;
            background-color: $colorWhite;
            position: relative;
            // smooth scrolling on ios devices
            -webkit-overflow-scrolling: touch;


            table.product-table {
                table-layout: fixed;
                /*min-width: 940px;*/
                color: $colorBlack;
                position: relative;
                font-size: 1.15em;
                width: 100%;

                @media screen and (max-width: 991px) {
                    border-collapse: separate;
                    border-spacing: 1.5em 0;

                    td.separator {
                        display: none;
                    }
                }

                @media screen and (max-width: 991px) {
                    width: 180vw;
                }

                @media screen and (max-width: 500px) {
                    width: 320vw;
                }

                td.separator {
                    width: 1.2em;
                    padding: 0;
                    border: none;
                    background-color: white;
                }

                td {
                    padding: 1em 1.5em;
                }

                td.highlight {
                    padding-top: .4em;
                }

                .product-description td {
                    padding: 2.25em 1.5em 1em;
                }

                .details {
                    white-space: pre-wrap;
                }

                thead {
                    font-weight: bold;

                    .product-header {
                        .product-next-btn {
                            padding: 0;
                        }

                        .link {
                            font-weight: normal;
                            font-size: smaller;
                        }
                    }
                }

                .full-cover-link {
                    color: $colorBlack;
                    text-transform: uppercase;
                    white-space: pre-wrap;
                    font-weight: bold;
                    /*.fas {
                    font-size: 1.5em;
                    font-weight: 100;
                    margin-left: .6em;
                }*/
                }

                .info {
                    color: white;
                    padding: 10px;

                    &:hover,
                    &:active,
                    &:focus {
                        text-decoration: none;
                    }
                }

                .product-highlights-title td {
                    padding: .5em 1.5em !important;
                }

                #product-header-sticky {
                    position: fixed;

                    .product-header.sticky {
                        width: 25%;

                        .product-title {
                            display: inline-block;
                            width: 55%;
                            margin-bottom: 0;
                            font-size: 1.1em;
                            text-align: left;
                        }

                        .product-price {
                            display: inline-block;
                            width: 40%;
                            vertical-align: top;
                            margin-top: .7em;
                            font-size: 1.1em;

                            .product-price-interval {
                                font-size: x-small;
                            }
                        }

                        .product-next-btn {
                            margin-top: 0;
                            margin-bottom: 0;
                        }
                    }
                }

                .product-header {
                    border-top: 0 none;
                    padding: 1.5em 1.5em 1.2em
                }
                /*@media screen and (min-width: 1024px) {
                .product-header {
                    border-top: 0 none;
                    padding: 1.5em 1.5em 1.2em
                }
            }

            @media screen and (max-width: 1023px) {
                .product-header {
                    border-top: 0;
                }
            }*/
                .product-description {
                    vertical-align: top;
                }

                .highlight {

                    .fas {
                        display: inline-block;
                        width: 10%;
                        margin-top: .1em;
                        margin-right: 0.2em;
                        vertical-align: top;
                        font-size: larger;
                    }

                    .highlight-name {
                        display: inline-block;
                        width: 80%;
                    }
                }

                tbody {
                    .section-header {
                        color: $colorWhite;
                        background-color: $colorBlack;
                        font-size: small;

                        td {
                            padding-bottom: 2em;

                            h4 {
                                border-width: 0 0 1px 0;
                                border-style: solid;
                                /*margin-top: 0;*/
                                margin-bottom: 1em;
                                padding-top: 0;
                                padding-bottom: 1em;
                                font-size: 1.7em;

                                .fas {
                                    position: relative;
                                    top: -.1em;
                                    margin-right: .6em;
                                }
                            }

                            .upsell {
                                font-weight: lighter;
                            }
                        }
                    }

                    .fas { // fontawesome icon
                        font-size: large;
                        text-align: center;
                        vertical-align: middle;
                    }

                    .legend {
                        background-color: $colorGrayLight;

                        td {
                            padding-top: 1em;
                            padding-bottom: 1em;
                        }

                        .item {
                            margin-top: .4em;
                            margin-bottom: .4em;
                            font-weight: bold;

                            .fas {
                                font-size: x-large;
                                width: .8em;
                                margin-right: .7em;
                                display: inline-block;
                            }

                            .fas.fa-exclamation {
                                font-size: larger;
                                width: 1.12em;
                            }

                            .description {
                                display: inline-block;
                                width: 75%;
                                vertical-align: middle;
                            }
                        }
                    }

                    .item {
                        h4 {
                            font-weight: bold;
                            font-size: 1em;
                            display: inline-block;
                            width: 72%;
                        }

                        .service-cover-icon {
                            width: 13%;
                            margin-top: .5em;
                            margin-right: 0.25em;
                            vertical-align: top;
                        }

                        .expander {
                            width: 8%;
                            font-size: unset;
                            vertical-align: top;
                            margin-top: .7em;
                            margin-left: 0.26em;
                        }

                        .details {
                            font-size: .87em;
                        }

                        &.has-hover {
                            transition: background-color 200ms ease;

                            &:hover {
                                background-color: #EEE;
                                cursor: pointer;
                                transition: background-color 200ms ease;
                            }
                        }
                    }

                    .extras {
                        vertical-align: top;

                        .no-extras {
                            color: $colorGray80;

                            .fas {
                                color: inherit;
                            }
                        }

                        .link-wrapper {
                            margin-top: 1em;
                        }

                        .full-cover-link .fas {
                            font-size: inherit;
                            vertical-align: inherit;
                            margin-left: 0.57em;
                        }
                    }
                }
            }

            td {
                border-color: $colorBlack;
                border-style: solid;
            }

            .toggle-details {

                .expander {
                    float: right;
                    margin-top: 0.2em;
                }

                td {
                    border-width: 1px;
                    background-color: $brand-primary;
                    text-transform: uppercase;
                }

                td:hover {
                    cursor: pointer;
                    background-color: $colorBlack;
                    color: $brand-primary;
                    /*border-color: $brand-primary;*/
                }
            }
        }

        .carousel-scroll-button {
            position: fixed;
            top: 50%;
            height: 120px;
            line-height: 120px;
            text-align: center;
            width: 120px;
            background: rgba(191,191,191,0.5);
            border-radius: 7px;
            margin: -60px -60px;
            z-index: 1;
            font-size: 40px;
            color: $colorBlack;

            .fas {
                vertical-align: inherit;
                top: 39px;
                position: absolute;
            }
        }

        .carousel-scroll-button-left {
            left: 0;

            .fas {
                right: 20px;
            }
        }

        .carousel-scroll-button-right {
            right: 0;

            .fas {
                left: 20px;
            }
        }

        .carousel-scroll-button-left.slide-out {
            left: -180px;
            transition-property: left;
            transition-duration: .5s;
        }

        .carousel-scroll-button-left.slide-in {
            transition-property: left;
            transition-duration: .5s;
        }

        .carousel-scroll-button-right.slide-out {
            right: -180px;
            transition-property: right;
            transition-duration: .5s;
        }

        .carousel-scroll-button-right.slide-in {
            transition-property: right;
            transition-duration: .5s;
        }
    }
    /* =============================================================================
   product details
   ========================================================================== */
.product-details {
    h2, h3, h4 {
        font-weight: bold;
    }

    .highlights {
        h3 {
            font-size: 1.4em;
        }
    }

    .service {
        margin: .8em 0;

        .service-cover-icon {
            display: inline-block;
            vertical-align: top;
            margin: .07em .51em 0 0;
            font-size: large;
        }

        .service-name {
            display: inline-block;
            width: 80%;
        }
    }

    .view-all-link {
        a {
            text-transform: capitalize;
            display: block;
            margin: .5em 1.2em;
        }
    }

    .cover-section {
        margin-bottom: 2.5em;

        table {
            margin: 20px;
        }

        table tr td {
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
            border-left: 0px;
            border-right: 0px;
            padding: 10px;
        }

        .borderless {
            border-top: none !important;
        }

        .item .details {
            margin-left: 11%;
            padding-left: .25em;
            padding-bottom: .5em;
        }

        .item-header {
            background: transparent;
            appearance: none;
            border: 0;
            width: 100%;
            text-align: left;
            transition: background-color 200ms ease;

            &:hover,
            &:active,
            &:focus {
                background-color: #EEE;
                cursor: pointer;
                transition: background-color 200ms ease;
            }
        }
    }

    .section-header {
        background-color: $brand-primary;
        color: $colorBlack;
        padding: .3em 0;

        .fas {
            color: $colorBlack;
        }
    }

    .item, .section-header {
        h4 {
            font-weight: bold;
            font-size: 1em;
            display: inline-block;
            width: 72%;
        }

        .service-cover-icon {
            width: 8%;
            vertical-align: top;
            margin: .35em 0.18em .2em 3%;
            font-size: 1.5em;
        }
    }

    .item {
        border-style: solid;
        border-width: 1px 0;
        margin: -1px 0;
        border-color: $colorGrayLight;

        .expander {
            width: 1.5em;
            float: right;
            font-size: 1.3em;
            vertical-align: top;
            margin-top: .5em;
        }
    }

    #sticky-panel {
        position: fixed;
        top: 16em;
        z-index: 10;
    }

    .product-header, .extras-header {
        padding: 1em;
        border: none;
        // margin: 1em 0;
        text-align: left;

        h3 {
            font-size: large;
            margin-top: .5em;
        }

        .product-title {
            font-size: 1.4em;
        }

        .product-price sup {
            font-size: .5em;
            margin-right: 0.12em;
            top: 0;
        }
    }

    .product-header {
        color: white;
        background-color: #000;

        .info {
            color: white;
            padding: 5px;
        }

        .product-title {
            height: initial;
        }

        .row {
            position: relative;
            margin-bottom: .8em;

            .bottom-right {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        .selection {
            font-weight: lighter;
            /*font-size: x-small;*/
            select {
                font-weight: 600;
                width: 100%;
            }
        }

        .blurb {
            font-size: small;
            font-weight: lighter;
            margin-top: 1em;
            display: none;

            @media screen and (min-width: 768px) {
                display: block;
            }
        }
    }

    .product-header.non-mobile {
        display: none;

        @media screen and (min-width: 992px) {
            display: block;
        }

        .product-price {
            font-size: 3.2em;
        }
    }

    .product-header.mobile {
        display: block;
        margin: 1em 0 0;
        width: 100%;

        @media screen and (min-width: 992px) {
            display: none;
        }

        .product-next-btn {
            margin-top: 0;
        }
    }

    .blurb {
        background-color: $colorPurple;
        font-size: small;
        width: 100%;
        height: 130px;
        padding: 1.2em;

        p {
            margin: 0;
            margin-right: 40%;
        }

        &.immigration-stamp {
            background: $colorPurple url("../Images/immigration-stamp.png") no-repeat;
            background-position-y: center;
            background-position-x: 95%;
            background-size: 130px;
        }
    }

    .extras-header {
        margin-top: 2.4em;
        background-color: $colorPistachio;
        color: $colorBlack;
        font-size: small;
        width: 100%;

        .product-next-btn {
            margin: 0;
            border-color: $colorBlack;
            margin-bottom: .2em;
        }

        .product-next-btn:hover, .product-next-btn:focus {
            color: $colorPistachio;
        }

        a {
            margin-left: auto;
            margin-right: 0;
            max-width: 265px;
        }
    }

    * {
        box-sizing: border-box;
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        transition: opacity .3s ease;
        align-items: center;
        padding: 10px;
        display: flex;
    }

    .modal-container {
        max-width: 600px;
        margin: 40px auto 0;
        padding: 10px 10px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 5px 0;
        font-family: 'Montserrat', 'sans-serif';
        font-weight: 500;
    }


    .text-right {
        text-align: right;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    #product-header-sticky {
        position: fixed;
        z-index: 2;

        @media screen and (min-width: 992px) {
            display: none;
        }

        width: 25%;

        .product-title {
            display: inline-block;
            margin-bottom: 0;
            font-size: 1.4em;
            text-align: left;
        }

        .product-price {
            display: inline-block;
            vertical-align: top;
            margin-top: .7em;
            font-size: 1.1em;

            .product-price-interval {
                font-size: x-small;
            }
        }

        .product-next-btn {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    a.link-back {
        text-transform: uppercase;
        cursor: pointer;
    }
}
    /* =============================================================================
   Helper Classes

   * Some classes inspired by Bootstrap 4
   ========================================================================== */

    .text-center {
        text-align: center !important;
    }

    .d-block {
        display: block !important;
    }

    .d-flex {
        display: flex !important;
    }

    .flex-column {
        flex-direction: column !important;
    }

    .align-items-center {
        align-items: center;
    }

    .justify-content-center {
        justify-content: center;
    }

    .flex-column-reverse {
        flex-direction: column-reverse !important;
    }

    .max-width-none {
        max-width: none !important;
    }
    /* =============================================================================
   Media Object
   ========================================================================== */

    .media-object {
        display: flex;
        flex-direction: column-reverse;

        @media screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;

            &:nth-child(odd) {
                flex-direction: row-reverse;
            }

            & div:first-of-type {
                flex-basis: 74%;
            }

            & div:last-of-type {
                flex-basis: 24%;
            }
        }
    }
    /* =============================================================================
   Why Choose Frank page
   ========================================================================== */

    .why-choose-frank {
        h1 {
            margin-bottom: 4rem;
        }

        h2 {
            font-weight: 700 !important;
            margin: 0;
        }
    }