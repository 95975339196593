// Frank 2018: SASS variables

// COLORS
$colorGreen:         #7ED321;
$colorRed:           #D0021B;
$colorOrange:        #FFB25B;
$colorPurple:        #AC4FC6;
$colorMediumPurple:  #7462e0;
$colorSkyBlue:       #00B5E2;

// GRAYS
// @TEMP SG denotes that it's a color from the 2018 styleguide.
$colorBlack:         #000000; // SG
$colorOffBlack:      #282828;
$colorGray20:        #353638;
$color-neutral-dark: #333333; // SG
$colorGray40:        #414042;
$color-neutral-mid:  #646569; // SG
$colorGrayMid:       #bcc4c5;
$colorGray60:        #a6a9ad;
$colorGray80:        #999999; // SG
$colorBlueDark10:    #899799;
$colorGray70:        #ced0d2;
$colorCoolGray:      #CCCCCC; // SG
$colorGrayLight:     #F2F2F2;
$colorWhite:         #FFFFFF;

// Frank palette
$colorPistachio:     #8fe2b0;
$colorPistachioDark: darken($colorPistachio, 10%);
$colorPistachioLight:lighten($colorPistachio, 10%);

// Frank: project color variables
////////////////////////////////////////////////////////////////////////////////

// Primary colors
$brand-primary:       $colorPistachio;
$brand-primary-light: lighten($brand-primary, 10%);
$brand-primary-dark: #00BF6F; // From SG

// Secondary colors
$brand-secondary-orange:  $colorOrange;
$brand-secondary-purple:  $colorPurple;
$brand-secondary-blue:    $colorSkyBlue;

// Error
$color-error: $colorRed;

// Success
$color-success: $colorGreen;

// Warning
$color-warning: $colorOrange;

// Disabled
$color-disabled: $colorGray80;

// Fonts
////////////////////////////////////////////////////////////////////////////////

// Primary font (loaded from Google fonts)
$font-body: 'Montserrat';
$fontawesome-font-family: 'Font Awesome 5 Free';
