// Base button styles. Apply this class to all buttons.
.btn,
a.btn,
button.btn {
    border: 2px solid transparent;
    border-radius: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    letter-spacing: 0.1rem; // 1px / 10px root element
    line-height: 4.6rem; // make btn 50px tall (minus 2px border)
    min-width: 250px;
    max-width: 400px;
    padding: 0 30px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 200ms ease;
    white-space: normal;

    &:after,
    &:hover:after {
        display: none;
    }
}

// Button Primary.
//
// (hero in the styleguide)
////////////////////////////////////////////////////////////////////////////////

.btn--primary,
.btn-primary,
a.btn-primary {
    background: transparent;
    border: 2px solid $colorBlack;
    color: $colorBlack;

    &:hover,
    &:active,
    &:focus {
        background-color: $colorBlack;
        border-color: $colorBlack;
        color: $colorWhite;
    }

    &.is-disabled,
    &:disabled {
        border-color: $color-disabled;
        color: $color-disabled;
    }
}

// btn--primary variant: on dark bg
.btn--primary-on-dark,
.btn-primary-on-dark,
a.btn-primary-on-dark {
    border-color: $brand-primary;
    color: $brand-primary;

    &:hover,
    &:active,
    &:focus {
        background-color: $brand-primary;
        border-color: $brand-primary;
        color: $color-neutral-dark;
    }
}

// Button secondary
////////////////////////////////////////////////////////////////////////////////

.btn--secondary,
.btn-secondary,
a.btn-secondary {
    border: 2px solid $colorBlack;
    background-color: $colorBlack;
    color: $colorWhite;

    &:hover,
    &:active,
    &:focus {
        background-color: transparent;
        border-color: $colorBlack;
        color: $colorBlack;
    }

    &.is-disabled,
    &:disabled {
        border-color: $color-disabled;
        color: $color-disabled;
    }
}

// btn--secondary variant: on dark bg
.btn--secondary-on-dark,
a.btn-secondary-on-dark {
    background-color: $brand-primary;
    color: $color-neutral-dark;
}

// Button tertiary
//
// ('Text link button' in styleguide)
////////////////////////////////////////////////////////////////////////////////

.btn--tertiary,
.btn-tertiary {
    color: $colorBlack;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }

    &.is-disabled,
    &:disabled {
        color: $color-disabled;
    }
}

// Button quarternary
//
// Solid green background / black background :hover
////////////////////////////////////////////////////////////////////////////////

.btn--quarternary,
.btn-quarternary {
    background-color: $brand-primary;
    color: $colorBlack;

    &:hover,
    &:active,
    &:focus {
        color: $colorWhite;
        background-color: $colorBlack;
    }

    &.is-disabled,
    &:disabled {
        background-color: $color-disabled;
        color: $colorWhite;
    }
}

// Button with icon
//
// @TODO finish. Which icons are to be applied to these buttons?
////////////////////////////////////////////////////////////////////////////////

.btn--has-icon {
    @extend .btn--tertiary;
}

// Button utility classes
////////////////////////////////////////////////////////////////////////////////

// Button: small 
.btn-small,
a.btn-small {
    min-width:  auto;
}

// Button: default
// Apple to <button> to remove default styles
.btn--default {
    appearance: none;
    background: transparent;
    display: inline-block;
    min-width: auto;
}